<template>
  <div :class="[fullwidth ? 'w-full' : '']">
    <div
      :class="[
        inset
          ? !!invalid
            ? 'field-inset-invalid w-full'
            : 'field-inset-default w-full'
          : '',
        'relative h-full',
        disabled && inset ? 'bg-gray-100' : null,
      ]"
    >
      <label
        v-if="label"
        fullwidth
        class="block text-gray-700"
        :class="[inset ? 'text-xs font-bold' : 'mb-2 text-sm font-medium']"
        >{{ label }}
        <span class="tooltip text-red-600" v-if="required">
          *
          <span class="tooltiptext" style="width: 120px">
            Kolom Wajib Diisi
          </span>
        </span>
      </label>
      <slot>
        <textarea
          v-if="tag === 'textarea'"
          v-bind="props"
          v-model="inputVal"
          v-on:change="handleChange"
          v-on:input="handleInput"
          v-on:focus="handleFocus"
          v-on:blur="handleBlur"
        />
        <input
          v-else
          ref="input"
          :type="type"
          :accept="accept"
          v-bind="props"
          v-model="inputVal"
          v-on:change="handleChange"
          v-on:input="handleInput"
          v-on:focus="handleFocus"
          v-on:blur="handleBlur"
        />
      </slot>
    </div>
    <p class="mt-2 text-sm text-red-600" v-if="!!invalid">{{ message }}</p>
  </div>
</template>

<script>
import debounce from 'debounce';
import { inputCurrency } from '@/services/currency.service.js';
import dayjs from 'dayjs';

export default {
  name: 'BaseInput',
  props: {
    tag: {
      type: String,
      default: 'input',
    },
    type: {
      type: String,
      default: 'input',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withLabel: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    invalid: null,
    value: null,
    label: String,
    placeholder: String,
    min: String,
    max: String,
    message: String,
    id: String,
    autocomplete: String,
    rows: String,
    size: {
      type: String,
      default: 'md',
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
    debounce: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    accept: String
  },
  emits: ['input', 'native-input', 'change', 'focus'],
  computed: {
    inputVal: {
      get: function () {
        if (this.type == 'date') {
          return dayjs(this.value).format('YYYY-MM-DD');
        }
        if (this.currency) {
          // input type="text", kalo number nnt error
          const val = inputCurrency(this.value);
          return val;
        }
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
    props: function () {
      const sizes = {
        sm: 'h-8 px-2',
        md: this.tag === 'textarea' ? 'min-h-10' : 'h-10',
        'padding-md': 'p-2'
      };

      return {
        class: [
          'block bg-white sm:text-sm',
          this.inset
            ? 'w-full border-0 px-0 pb-0 mt-1 pt-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm'
            : 'rounded-md border px-2',
          this.shadow ? (this.inset ? '' : 'shadow') : '',
          this.fullwidth ? 'w-full' : '',
          this.invalid
            ? 'border-red-300 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
            : 'border-gray-300 focus:border-green-500 focus:ring-green-500',
          this.disabled ? 'bg-gray-100' : '',
          this.inset ? '' : sizes[this.size],
          this.classes.input,
        ],
        placeholder: this.placeholder,
        min: this.min,
        max: this.max,
        id: this.id,
        autocomplete: this.autocomplete,
        rows: this.rows,
        disabled: this.disabled,
        required: this.required,
      };
    },
  },
  methods: {
    debounceEmitNativeInput: debounce(function () {
      this.$emit('native-input');
    }, 500),
    handleChange: function (e) {
      this.$emit('change', e);
    },
    handleInput: function (e) {
      if (this.debounce) {
        this.debounceEmitNativeInput();
      } else {
        this.$emit('native-input', e.target.value);
      }
    },
    handleFocus: function () {
      this.$emit('focus');
    },
    handleBlur: function (e) {
      this.$emit('blur', e.target.value);
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
  },
};
</script>
